import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styles from '../assets/css/module/common.module.css'
import { useState } from 'react';
import dayjs from 'dayjs'
import { saveAs } from 'file-saver';
import XMLViewer from 'react-xml-viewer'

import Papa from "papaparse";
const { buildGPX, GarminBuilder } = require('gpx-builder');

const { Point } = GarminBuilder.MODELS;
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const Home = () => {
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const [headerCSV, setHeaderCSV] = useState([]);
  const [result, setResult] = useState(null);

  const fileHandle = (e) => {
    setFile(e.target.files[0])
    Papa.parse(e.target.files[0], {
      worker: true,
      delimiter: ",",
      header: false,
      complete: (result) => {
        let tmparr = result.data
        tmparr = tmparr.filter(item => item !== result.data[0])
        tmparr.pop()
        setHeaderCSV(result.data[0])
        setData(tmparr);
      }
    }
    );
  };

  const exporttogpx = (e) => {
    let tmpPoint = []
    data.map(x => {
      tmpPoint.push(
        new Point(x[9], x[14], {
          time: new Date(dayjs.utc(x[3], "MM-DD-YYYY H:mm").format()),
          ele: parseFloat((parseFloat(x[15]) * 0.3048).toFixed(2)),
          // ele: parseFloat(x[15]),
          // hr: 120,
          speed: parseFloat(x[16]),
          bearing: parseFloat(x[17]),
        })
      )
    })
    const gpxData = new GarminBuilder();
    gpxData.setSegmentPoints(tmpPoint);
    let rs = buildGPX(gpxData.toObject()).substring(buildGPX(gpxData.toObject()).indexOf("\n") + 1)
    setResult(rs)
    saveAs(new Blob([rs]), "output.gpx");
  }

  return (
    <div>
      <div>
        <div className={`${styles["btn-dv"]}`}>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Browse File CSV
            <VisuallyHiddenInput type="file" accept='.csv,.CSV' onChange={e => fileHandle(e)} />
          </Button>
          <Button variant="contained" onClick={exporttogpx}>Convert to GPX</Button>
        </div>
        <div className={`${styles["a-dv"]}`}>
          <div>
            <p>{file.name}</p>
          </div>
          <div className={`${styles["rs-sec"]}`}>
            <XMLViewer xml={result !== null ? result : ''} />
          </div>
        </div>

        {/* <div className={`${styles["tbl"]}`}>
          <table>
            <thead>
              <tr>
                {
                  headerCSV !== null && headerCSV !== undefined && headerCSV.length > 0 ? headerCSV.map((x, i) => {
                    return <th key={i}>{x}</th>
                  }) : null
                }
              </tr>
            </thead>
            <tbody>
              {
                data !== null && data !== undefined && data.length > 0 ? data.map((x, i) => {
                  return <tr key={i}>{
                    x.map((dtl, j) => {
                      return <td key={j}>{dtl}</td>
                    })}
                  </tr>
                }) : null
              }

            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}

export default Home;
